<template>
  <div v-loading="loadingData" style="min-height:300px">
    <HbiBuild :formTemplate="jsonData" :models="models" ref="hbiBuild" />
  </div>
</template>
<script>
import HbiBuild from '@/components/hbi/hbi-build/index'
import { getInfoById as getById, saveReport } from '@/api/hbi/report.js'

export default {
  components: {
    HbiBuild
  },
  data () {
    return {
      loadingData: false,
      jsonData: {},
      id: '',
      models: {},
    }
  },
  mounted () {
    this.id = this.$route.query.id;
    if (!this.id) {
      this.id = this.$route.params.id
    }
    this.saveReport();
    this.init()
  },
  created () {

  },
  methods: {
    init () {
      if(!this.id){
        return
      }
      this.loadingData = true
      getById(this.id).then(({ data }) => {
        if (data && data.code === 0) {
          //判断当前系统日期是否大于截止日期，若是，则提示访问时间过期
          /* if (data.data.systemDate != '' && data.data.showEndTime != '') {
               let date = new Date(data.data.systemDate)
               let date2 = new Date(data.data.showEndTime)
               if (date > date2) {
                   this.$alert('已超过展示截止日期，无法预览。', '提示', {
                       confirmButtonText: '确定'
                   });
                   return;
               }
           }*/
          // 取得表单
          const formModel = data.data.templateData
          const forms = formModel ? JSON.parse(formModel) : null
          this.jsonData = forms
          this.$nextTick(() => {
            this.$refs.hbiBuild.initModelKey(true)
          })
          if (data.data.reportType == 2) {
            // 如果是app 则刷新title
            const name = data.data.name
            document.title = name
          }
        } else {
          this.id = ''
        }
      }).finally(_ => {
        this.loadingData = false
      })
    },
    /**
     * 统计报表访问量
     */
    saveReport () {
      if(this.id){
        saveReport(this.id).then(({ data }) => { })
      }
    }
  }
}
</script>